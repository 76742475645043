<template>
  <div v-loading="fullscreenLoading">
    <el-form :inline="true" :model="examArchiveMsgCondition">
      <!-- 考试归档情况 -->
      <el-form-item label="归档情况" class="formItemBoxStyle">
        <el-select
          v-model="examArchiveMsgCondition.archivesStatus"
          placeholder="全部"
          style="margin-right: 20px; width: 200px"
          @change="findExamArchiveMsgBtn"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option label="未检查未归档" :value="1"></el-option>
          <el-option label="已检查未归档" :value="2"></el-option>
          <el-option label="已归档" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <!-- 考试名称 -->
      <el-form-item label="考试名称" class="formItemBoxStyle">
        <el-input
          v-model="examArchiveMsgCondition.examName"
          placeholder="请输入考试名称"
          clearable
          style="width: 200px; margin-right: 20px"
          @input="findExamArchiveMsgBtn"
        ></el-input>
      </el-form-item>
      <!-- 考试编号 -->
      <el-form-item label="考试编号" class="formItemBoxStyle">
        <el-input
          v-model="examArchiveMsgCondition.examCode"
          placeholder="请输入考试编号"
          clearable
          style="width: 200px; margin-right: 20px"
          @input="findExamArchiveMsgBtn"
        ></el-input>
      </el-form-item>
      <!-- 查询和清空按钮 -->
      <el-form-item>
        <el-button type="primary" @click="findExamArchiveMsgBtn"
          >查询</el-button
        >
        <el-button type="primary" plain @click="resetExamArchiveMsgBtn"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row v-loading="tableLoading">
      <!-- 考试档案数据表格 -->
      <el-table
        :data="examArchiveMsgData"
        border
        width="100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column
          prop="examName"
          label="考试名称"
          center
          width="300"
        ></el-table-column>
        <el-table-column prop="archivesStatus" label="归档状态" width="120" center>
          <template slot-scope="scope">
            <span v-if="scope.row.archivesStatus === 1">未检查未归档</span>
            <span v-if="scope.row.archivesStatus === 2">已检查未归档</span>
            <span v-if="scope.row.archivesStatus === 3">已归档</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="examCode"
          label="考试编号"
          center
        ></el-table-column>
        <el-table-column
          prop="subjectTypeList"
          label="考试科目"
          center
        ></el-table-column>
        <el-table-column prop="arrangeModel" label="排考方式" center>
          <template slot-scope="scope">
            {{ scope.row.arrangeModel | arrangeModelFilters }}
          </template>
        </el-table-column>
        <el-table-column prop="examStatus" label="考试状态" center>
          <template slot-scope="scope">
            <span v-if="scope.row.examStatus == 2">等待考试</span>
            <span v-else-if="scope.row.examStatus == 3">正在考试</span>
            <span v-else-if="scope.row.examStatus == 4">考试完成</span>
            <span v-else-if="scope.row.examStatus == 5">等待补考</span>
            <span v-else-if="scope.row.examStatus == 6">正在补考</span>
            <span v-else-if="scope.row.examStatus == 7">考试终止</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="考后数据" center>
          <template slot-scope="scope">
            <span v-if="handleExamedDataStatue(scope.row) === 1">已上传</span>
            <span
              v-if="handleExamedDataStatue(scope.row) === 2"
              style="color: red"
              >未上传</span
            >
            <span v-if="handleExamedDataStatue(scope.row) === 3">未知</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="markStatus" label="阅卷情况" center>
          <template slot-scope="scope">
            <span v-if="scope.row.markStatus == 1">等待阅卷</span>
            <span v-else-if="scope.row.markStatus == 2">正在阅卷</span>
            <span v-else-if="scope.row.markStatus == 3">完成阅卷</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="dataSynSituation"
          label="数据同步情况"
          width="120"
          center
        >
          <span>无</span>
        </el-table-column> -->
        <el-table-column
          prop="examineeCount"
          label="考生数量"
          center
        ></el-table-column>
        <el-table-column
          prop="examineeAchievementCount"
          label="成绩情况"
          center
        ></el-table-column>
        <el-table-column label="操作" width="300" center>
          <template slot-scope="scope">
            <div v-if="scope.row.archivesStatus == 3">
              <el-button 
              type="primary"
              size="small"
              v-throttle
              style="width: 116px;"
              @click="handleCheckArchiveDetailBtnClick(scope.row)" 
              >查看归档
              </el-button>
              <el-button 
              type="danger"
              size="small"
              v-throttle
              @click="examDataArchiveBtn(scope.row, 'del', scope.row.examName)"
              >清除归档
              </el-button>
            </div>
            <div v-else>
            <el-button 
              size="small" 
              type="success" 
              v-throttle 
              @click="examDataCheck(scope.row)"
              >数据完整性检查</el-button>
            <el-button
              :disabled="handleExamDataArchiveIsDisabled(scope.row) && scope.row.archivesStatus == 1"
              type="primary"
              size="small"
              v-throttle
              @click="examDataArchiveBtn(scope.row, 'add')"
              >数据归档</el-button
            >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        style="margin-top: 10px;"
        @size-change="handleSizeChangeExamAcrhiveMsgPage"
        @current-change="handleCurrentChangeExamAcrhiveMsgPage"
        :current-page="examAcrhiveMsgPage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="examAcrhiveMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="examAcrhiveMsgPage.totalSize"
      ></el-pagination>
    </el-row>
    

    <!-- 对话框是否显示 -->
    <!-- 数据检查中的对话框 -->
    <el-dialog
      v-if="checkDataDialogStatus === 'pendding'"
      title="数据检查"
      :visible.sync="dialogVisible"
      width="600px"
      top="30vh"
    >
      <div style="text-align: center">
        <img src="../../marking/img/dataLoading.png" />
      </div>
      <div
        style="
          font-size: 18px;
          text-align: center;
          font-weight: 500;
          margin: 10px 0;
        "
      >
        数据正在检查，请稍后...
      </div>
      <div style="width: 500px; margin: 0 auto; color: #ccc; font-weight: 500">
        数据检查过程会持续一定的时间，你可以点击关闭弹窗，进行其他操作，检查完成会有通知弹窗告知结果
      </div>
      <span slot="footer" class="dialog-footer" style="margin-right: 235px">
        <el-button @click="dialogVisible = false">关闭弹窗</el-button>
      </span>
    </el-dialog>

    <!-- 数据检查后的对话框 -->
    <!-- 当检查结果有错误的时候 -->
    <el-dialog
      v-if="checkDataDialogStatus === 'abnormal'"
      title="数据检查"
      :visible.sync="dialogVisible"
      width="600px"
      top="30vh"
    >
      <!-- 头部 -->
      <div
        style="
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
        "
      >
        <div style="margin-right: 10px">
          <img src="../../marking/img/dataAbnormal.png" />
        </div>
        <div>
          <div
            style="
              margin-bottom: 5px;
              text-align: left;
              color: rgb(254, 70, 64);
              font-size: 18px;
            "
          >
            检查完成
          </div>
          <div style="color: #ccc">可在异常处理补充上传</div>
        </div>
      </div>
      <!-- 中部 -->
      <div style="display: flex; justify-content: space-between; margin: 10px">
        <!-- 数据检查结果与用时 -->
        <div>
          <i class="el-icon-document"></i>
          <span style="color: #ccc">本次共检查数据：</span
          ><strong>{{ dataTestObj[testExamId].dataCheckCount }}条</strong>
        </div>
        <div>
          <i class="el-icon-time"></i>
          <span style="color: #ccc">总用时：</span
          ><strong>{{
            transformTimeStamp(dataTestObj[testExamId].dataTestTimes)
          }}</strong>
        </div>
      </div>
      <!-- 中部数据检查的问题 -->
      <div
        ref="dialogContent"
        style="
          background-color: rgb(248, 248, 248);
          border-radius: 5px;
          padding: 10px;
        "
      >
        <div style="margin-bottom: 10px">你检查的数据有如下问题：</div>

        <!-- 问题项 -->
        <div
          v-for="(item, i) in unUploadPicMsgArr"
          :key="i"
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <img
            style="
              width: 18px;
              object-fit: cover;
              transform: scale(0.8);
              position: absolute;
            "
            src="../../marking/img/warnPoint.png"
          />
          <span style="margin-left: 20px"
            >有{{ item.count }}位考生{{ item.name }}</span
          >
          <div
            style="
              overflow: hidden;
              border-bottom: 1px dashed #ccc;
              margin-bottom: 8px;
            "
            :style="{ width: dashLineWidth(item.name) }"
          ></div>
          <span
            v-if="item.type === 'pic'"
            style="
              color: rgb(24, 144, 255);
              cursor: pointer;
              text-decoration: underline;
            "
            @click="goToPicHandlePage(testRowData)"
            >处理问题</span
          >
          <span
            v-if="item.type === 'video'"
            style="
              color: rgb(24, 144, 255);
              cursor: pointer;
              text-decoration: underline;
            "
            @click="testBtn(testRowData)"
            >处理问题</span
          >
        </div>
      </div>

      <span slot="footer" class="dialog-footer" style="margin-right: 175px">
        <el-button @click="testAgain" style="margin-right: 10px"
          >重新检查</el-button
        >
        <el-button @click="dialogVisible = false">关闭弹窗</el-button>
      </span>
    </el-dialog>

    <!-- 数据检查后的对话框 -->
    <!-- 当检查结果无错误的时候 -->
    <el-dialog
      v-if="checkDataDialogStatus === 'normal'"
      title="数据检查"
      :visible.sync="dialogVisible"
      width="600px"
      top="30vh"
    >
      <div style="text-align: center">
        <img src="../../marking/img/dataNormal.png" />
      </div>
      <div
        style="
          font-size: 18px;
          text-align: center;
          font-weight: 500;
          margin: 10px 0;
        "
      >
        数据检查完成，未发现问题
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin: 30px;
          padding: 0 30px;
        "
      >
        <!-- 数据检查结果与用时 -->
        <div>
          <i class="el-icon-document"></i>
          <span style="color: #ccc">本次共检查数据：</span
          ><strong>{{ dataTestCount }}条</strong>
        </div>
        <div>
          <i class="el-icon-time"></i>
          <span style="color: #ccc">总用时：</span
          ><strong>{{ transformTimeStamp(dataTestTimes) }}</strong>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="margin-right: 175px">
        <el-button @click="testAgain" style="margin-right: 10px"
          >重新检查</el-button
        >
        <el-button @click="dialogVisible = false">关闭弹窗</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  archiveExamApi,
  getExamArchiveListApi,
  dataCheckApi,
  delExamArchiveInfoApi
} from '@/api/archive/archiveManagement.js'
export default {
  name: 'archiveManagement',
  data() {
    return {
      // 分页条件查询考试档案信息
      examArchiveMsgCondition: {
        archivesStatus: 0, // 考试归档情况
        examName: '', // 考试名称
        examCode: '', // 考试编号
      },
      // 考试档案信息数组
      examArchiveMsgData: [],
      // 数据分页
      examAcrhiveMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      // 数据检查结果
      dialogVisible: false, // 对话框是否显示
      dataTestObj: {}, // 数据检查结果缓存
      testRowData: {}, // 测试结果缓存，用于重新检查按钮触发和处理问题时跳转的参数传递
      testExamId: '', // 记录当前打开弹框的 examid，用于重新检查按钮触发和处理问题时跳转的参数传递
      isDataCheckAgain: 1, // 是否进行重新检查，1表示首次检查，0表示重新检查
      dataTestCount: 0, // 检查数据总量
      dataTestTimes: 0, // 检查数据时长，单位毫秒
      checkDataDialogStatus: 'pendding',
      tableLoading: false,

      fullscreenLoading: false,
    }
  },
  created() {
    this.fetchExamArchiveList()
  },
  computed: {
    // 过滤掉没有问题的子项
    unUploadPicMsgArr() {
      if (this.dataTestObj[this.testExamId].dataCheckResult) {
        return this.dataTestObj[this.testExamId].dataCheckResult.filter(
          (item) => item.count !== 0
        )
      }``
    },
  },
  methods: {
    fetchExamArchiveList() {
      this.tableLoading = true
      let params = {
        examName: this.examArchiveMsgCondition.examName, // 考试名称
        examCode: this.examArchiveMsgCondition.examCode, // 考试编号
      }
      if(!!this.examArchiveMsgCondition.archivesStatus) {
        params = {
          ...params,
          archivesStatus: this.examArchiveMsgCondition.archivesStatus
        }
      }
      getExamArchiveListApi(
        this.examAcrhiveMsgPage.pageIndex,
        this.examAcrhiveMsgPage.pageSize,
        params
      ).then((res) => {
        if(res.success) {
          this.examArchiveMsgData = res.data
          this.examAcrhiveMsgPage.totalSize = res.total
        } else {
          this.$message.error(res.msg)
        }
        this.tableLoading = false
      })
    },
    handleCheckArchiveDetailBtnClick(row) {
      this.$router.push({
        path: "/archive/archiveDetail",
        query: {
          archiveInfo: JSON.stringify(row)
        }
      })
    },
    // 选择未归档或已归档考试数据
    handleChangeArchiveStatusSelect() {
      this.examAcrhiveMsgPage.pageIndex = 1
      this.examAcrhiveMsgPage.pageSize = 10
      this.fetchExamArchiveList()
    },
    // 查询数据按钮
    findExamArchiveMsgBtn() {
      this.examAcrhiveMsgPage.pageIndex = 1
      this.examAcrhiveMsgPage.pageSize = 10
      this.fetchExamArchiveList()
    },
    // 重置数据按钮
    resetExamArchiveMsgBtn() {
      this.examArchiveMsgCondition = {
        archivesStatus: 0,
        examName: '',
        examCode: '',
      }
      this.examAcrhiveMsgPage.pageIndex = 1
      this.examAcrhiveMsgPage.pageSize = 10
      this.fetchExamArchiveList()
    },
    // 数据检查功能，等数据检查的接口做好后，再来改变弹框的信息
    examDataCheck(row) {
      // console.log(row.examId);
      this.testExamId = row.examId // 记录 examId
      this.testRowData = row // 记录 row
      this.dialogVisible = true

      // 这里可以获取考试唯一的id ，根据id 往examObj 添加
      if (!this.dataTestObj[row.examId]) {
        // 进入此 if 表示第一次检查
        this.checkDataDialogStatus = 'pendding'
        this.$set(this.dataTestObj, row.examId, {
          dataTestStatus: 'pendding',
          dataCheckResult: {},
        })
        dataCheckApi(row.examId, this.isDataCheckAgain).then((res) => {
          const { data, code, msg } = res
          if (res.success) {
            // 获取数据判断检查是否不合格
            const {
              unPaperTestAnswerCount, // 未上传试无纸化试卷答案数量
              unReportCount, // 	未上传实验报告
              unMicroscopePictureCount, // 	未上传显微镜图片数量
              unAnswerHtmlImgAddrCount, // 	未上传试卷图片数量
              checkCount, // 检查数据量
              checkDataTimes, // 检查时长，单位毫秒
              unTopCameraCount, // 俯拍镜头
              unFrontCameraCount, // 正拍镜头
              unSideCameraCount, // 侧拍镜头
            } = data
            this.dataTestCount = checkCount
            this.dataTestTimes = checkDataTimes
            if (
              unPaperTestAnswerCount == 0 &&
              unReportCount == 0 &&
              unMicroscopePictureCount == 0 &&
              unAnswerHtmlImgAddrCount == 0
            ) {
              // 检查合格
              this.checkDataDialogStatus = 'normal'
              this.dataTestObj[row.examId].dataTestStatus = 'normal'
            } else {
              // 检查有不合格
              this.checkDataDialogStatus = 'abnormal'
              this.dataTestObj[row.examId].dataTestStatus = 'abnormal'
              this.dataTestObj[row.examId].dataCheckCount = checkCount
              this.dataTestObj[row.examId].dataTestTimes = checkDataTimes
              this.$set(this.dataTestObj[row.examId], 'dataCheckResult', [
                // {
                //   name: '未上传电子试卷答案数量',
                //   count: unPaperTestAnswerCount,
                //   type: 'pic',
                // }, // 未上传试无纸化试卷答案数量
                {
                  name: '未上传实验报告',
                  count: unReportCount,
                  type: 'pic',
                }, // 	未上传实验报告
                {
                  name: '未上传显微镜图片数量',
                  count: unMicroscopePictureCount,
                  type: 'pic',
                }, // 	未上传显微镜图片数量
                {
                  name: '未上传试卷拍照数量',
                  count: unAnswerHtmlImgAddrCount,
                  type: 'pic',
                }, // 	未上传试卷图片数量
                {
                  name: '未上传摄像头1',
                  count: unTopCameraCount,
                  type: 'video',
                }, // 俯拍镜头
                {
                  name: '未上传摄像头2',
                  count: unFrontCameraCount,
                  type: 'video',
                }, // 正拍镜头
                {
                  name: '未上传摄像头3',
                  count: unSideCameraCount,
                  type: 'video',
                }, // 侧拍镜头
              ])
            }
            this.fetchExamArchiveList()
          } else {
            this.$delete(this.dataTestObj, row.examId)
            this.$message.error(msg)
            return
          }
        })
      } else {
        // 进入此 if 说明是已经检查过了,根据数据改变弹框状态
        this.checkDataDialogStatus = this.dataTestObj[row.examId].dataTestStatus
      }
    },
    // 重新检查
    testAgain() {
      this.isDataCheckAgain = 0 // 重新检查
      this.$delete(this.dataTestObj, this.testExamId)
      this.examDataCheck(this.testRowData)
    },
    // 关闭对话框
    handleClose() {
      this.dialogVisible = false
    },
    // 跳转到照片处理页面
    goToPicHandlePage(row) {
      let subjectTypes = []
      let subjectTypeList = row.subjectTypeList.split(',')
      for (let i = 0; i < subjectTypeList.length; i++) {
        if (subjectTypeList[i] === '物理') {
          subjectTypes.push(1)
        } else if (subjectTypeList[i] === '生物') {
          subjectTypes.push(2)
        } else if (subjectTypeList[i] === '化学') {
          subjectTypes.push(3)
        }
      }
      row = {
        ...row,
        subjectTypes,
      }
      this.$router.push({
        path: `/marking/picHandleDetail?examInfo=${JSON.stringify(row)}`,
      })
    },
    testBtn(row) {
      let subjectTypes = []
      let subjectTypeList = row.subjectTypeList.split(',')
      for (let i = 0; i < subjectTypeList.length; i++) {
        if (subjectTypeList[i] === '物理') {
          subjectTypes.push(1)
        } else if (subjectTypeList[i] === '生物') {
          subjectTypes.push(2)
        } else if (subjectTypeList[i] === '化学') {
          subjectTypes.push(3)
        }
      }
      row = {
        ...row,
        subjectTypes,
      }
      this.$router.push({
        path: `/marking/abnormalDialog?
        examInfo=${JSON.stringify(row)}`,
      })
    },
    // 调用该方法可以将毫秒转变成 xx-xx-xx
    transformTimeStamp(time) {
      if (time < 1000) return '00:00:01'
      time = Math.floor(time / 1000)
      let hour = Math.floor(time / 3600)
      let min = Math.floor(time / 60)
      min >= 60 && (min = min % 60)
      let sec = Math.floor(time % 60)
      function testTransform(a) {
        if (a < 10) {
          return String(a).padStart(2, '0')
        }
        return a
      }
      return `${testTransform(hour)}:${testTransform(min)}:${testTransform(
        sec
      )}`
    },
    // 动态设置虚线的宽度
    dashLineWidth(name) {
      let len = name.length
      if (len > 10) return '30%'
      if (len === 10) return '40%'
      if (len < 10) return '45%'
    },
    // 数据归纳按钮
    examDataArchiveBtn(row, btnType,text = '该') {
      // this.$router.push({
      //   path: `/archive/archiveManagementDetail?archiveInfo=${JSON.stringify(
      //     row
      //   )}`,
      // });
      let words = btnType == 'add' 
      ? '确认是否对该考试进行归档？' 
      : `如果考试被删除，将无法进行二次归档。是否确认删除“${text}”考试?`
      let apiMap = {
        1: archiveExamApi,
        0: delExamArchiveInfoApi
      }
      this.$confirm(words, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.fullscreenLoading = true
          apiMap[btnType === 'add' ? 1 : 0](row.examId).then((res) => {
            if (res.success) {
              this.fullscreenLoading = false
              this.$message.success(btnType == 'add' ? '数据归档成功!' : '数据清除成功')
              this.fetchExamArchiveList()
            } else {
              this.fullscreenLoading = false
              this.$message.error(res.msg)
              return
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `已取消`,
          })
        })
    },
    // 关闭弹框
    handleClose() {
      this.dataCheckDialogVisible = false
    },
    // 分页页数改变回调
    handleSizeChangeExamAcrhiveMsgPage(val) {
      this.examAcrhiveMsgPage.pageIndex = 1
      this.examAcrhiveMsgPage.pageSize = val
      this.fetchExamArchiveList()
    },
    // 分页当前页改变回调
    handleCurrentChangeExamAcrhiveMsgPage(val) {
      this.examAcrhiveMsgPage.pageIndex = val
      this.fetchExamArchiveList()
    },
    //当选择项发生变化时会触发
    handleSelectionChange(val) {
      console.log(val)
    },
    handleExamDataArchiveIsDisabled(row) {
      if (!this.dataTestObj[row.examId]) {
        return true
      }
      return false
    },
    handleExamedDataStatue(row) {
      if (!this.dataTestObj[row.examId]) {
        return 3
      } else if (this.dataTestObj[row.examId].dataTestStatus === 'normal') {
        return 1
      } else {
        return 2
      }
    },
  },
  filters: {
    arrangeModelFilters(key) {
      switch (key) {
        case 1:
          return `定时定点`;
        case 2:
          return `随到随考`;
        case 3:
          return `定点不定时`;
        default:
          return `未选排考方式`;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.seLabel {
  display: inline-block;
  width: 90px;
  text-align: center;
  color: rgb(96, 98, 102);
  background-color: rgb(220, 230, 236);
}
</style>
